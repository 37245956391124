<template>
  <el-dialog v-model="dialogVisible" title="换绑手机号" :close-on-click-modal="false" width="560px">
    <div class="dp-f mt-20 mb-30">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" status-icon label-width="90px"
        class="demo-ruleForm w100" label-position="left">
        <el-form-item label="手机号" prop='phone'>
          <el-input v-model="ruleForm.phone" :maxlength="config.inputMaxlength" show-word-limit placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item label="请输入验证码" prop='code'>
          <div class="w100 dp-f">
            <el-input class="w100" v-model="ruleForm.code" :maxlength="config.inputMaxlength" show-word-limit
              placeholder="请输入验证码" maxlength="6" />
            <verificationCode class="ml-12" ref="verificationCodeRef" CSStype="2" @Emit="send"></verificationCode>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer dp-f jc-c">
        <oabutton v-model:loading="dialogloading" class="m-0-at" width='462' height='52'
          style="border-radius: 4px;font-size: 18px;" title="确认" CSStype=2 @buttonclick="submitForm">
        </oabutton>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits } from 'vue'
import { objdata_ret, objdata_set } from "@/utils/server/format";
import {handleCofirm,handleMessage} from "@/utils/server/confirm";
//    import { validPhone, validEmail, validPassword } from "@/utils/server/validate.js";
import { validPhone } from "@/utils/server/validate.js";
import { httpToken } from "@/utils/request";
import { loginOut } from "@/api/login"
import qs from "qs";
import verificationCode from "@/components/button/verificationCode.vue"//验证码组件
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogVisible = ref(false)//弹框显示
const id = ref(0);//操作的id
const verificationCodeRef = ref()//验证码组件ref
const state = reactive({
})
const ruleFormRef = ref(null);//表单ref
const ruleForm = reactive({//from 表单数据
  phone: '',//手机号
})
// 发送验证码
const send = (() => {
  if(ruleForm.phone==''){
    handleMessage('请输入手机号')
    return
  }
  if (!validPhone(ruleForm.phone)) {
    handleMessage('手机号格式错误')
    return
  }
  httpToken({
    method: "post",
    url: '/admin/app/sms',
    data: {
      'phone': ruleForm.phone,
      'exist': true,
      'appid': '1',
      'type': '10',
    }
  }).then(res => {
    unref(verificationCodeRef).send()
  })
})
// 校验手机号格式
const validPhoneData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入手机号'))
  } else {
    if (validPhone(value)) {
      httpToken({
        method: "post",
        url: '/admin/app/checkPhone',
        data: qs.stringify({
          'phone': value,
          'type': 3
        })
      }).then(res => {
        state.checkPhone = res.data
        // res.data 1手机号已存在 2手机号不存在
        if (res.data == 1) {
          callback(new Error(res.msg))
        } else {
          callback()
        }
      }).catch(() => {
        callback(new Error('校验手机号接口请求失败'))
      })
    } else
      callback(new Error('手机号格式错误'))
  }
}
const rules = reactive({//from 表单校验
  phone: [
    { validator: validPhoneData, trigger: 'blur' }
  ],
  code: [
    { required: true, message: '请输入验证码' },
    { min: 6, max: 6, message: '验证码的长度为6', trigger: 'blur' }
  ],
})
// 表单重置
let fromDataReset = (() => {
  objdata_ret(ruleForm)
})
let show = (() => {
  fromDataReset()
  dialogloading.value = false
  dialogVisible.value = true
  nextTick(() => {
    unref(ruleFormRef).clearValidate()//清除表单验证结果
  })
})
// 表单提交
const submitForm = async () => {
  dialogloading.value = true
  const form = unref(ruleFormRef)
  if (!form) {
    dialogloading.value = false
    return
  }
  await form.validate((valid) => {
    if (valid) {
      dataGeneration({
        code: ruleForm.code,
        phone: ruleForm.phone,
      })
    } else {
      dialogloading.value = false
    }
  })
}
// 数据生成
let dataGeneration = ((json, url = '/admin/sys-user/edit') => {
  httpToken({
    method: "post",
    url: url,
    data: json,
  }).then((res) => {
    loginOut()
  }).catch(() => {
    dialogloading.value = false
  })
})
defineExpose({
  show
});
</script>
<style lang="scss" scoped>
@import "@/styles/general/element/settingFrom.scss"; //element 样式重置

::v-deep .el-form-item__label {
  display: none;
}
</style>